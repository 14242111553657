import * as React from 'react'
import Layout from '../components/layouts/dark'
import Container from '../components/Container'
import NewFooterImage from '../images/mission_mockups.webp'
import ButtonLink from '../components/ButtonLink'
import AlertConfig from '../ALERTBAR_CONFIG'

const NotFoundPage = () => {
	return (
		<>
			<Layout>
				<section
					id="mission"
					className="bg-dark flex flex-col"
					style={{
						minHeight: `calc(100vh - ${
							AlertConfig.enabled ? '100px' : '60px'
						})`,
					}}
				>
					<Container no-vertical>
						<div className="grid gap-12 text-center py-12 lg:py-24">
							<h1>🧠</h1>
							<h1 className="text-black-faded text-6xl">404</h1>
							<h1 className="font-regular text-6xl text-white">
								page not found
							</h1>
							<div>
								<ButtonLink to="/">return</ButtonLink>
							</div>
						</div>
					</Container>
					<div className="w-full xl:w-3/5 mx-auto mt-auto">
						<div className="relative h-64 md:h-96 xl:h-0 xl:pt-[26.28%]">
							<div
								className="absolute inset-0 bg-center"
								style={{
									backgroundImage: `url(${NewFooterImage})`,
									backgroundSize: 'auto 100%',
								}}
							></div>
						</div>
					</div>
				</section>
			</Layout>
		</>
	)
}

export default NotFoundPage
